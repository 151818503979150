<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-05-29 11:04:14
 * @LastEditTime: 2021-12-13 14:57:43
-->
<template>
  <div
    class="layout"
    :class="'dashboardBg'"
  >
    <div class="layout-inner">
      <el-container>
        <!-- 顶部导航栏 -->
        <el-header height="65px">
          <Header @openCircle="openCircle" />
        </el-header>
        <!-- 内容区域 -->
        <div class="main">
          <Main />
        </div>
        <!-- 底部区域 -->
        <el-footer height="62px">
          <Footer />
        </el-footer>
      </el-container>
    </div>
    <div
      class="shadowMore"
      :style="
        'clip-path: circle(' + circleNum + 'rem at calc(100% - 7rem) 5rem);'
      "
    >
      <div class="shadowMore-router">
        <div class="router-item">
          <router-link
            v-for="(item, index) in routes"
            :key="index"
            tag="div"
            :to="item.path"
          >
            <div
              @mouseleave="routerLeave"
              @mouseenter="routerHover(index)"
              class="router"
              :class="
                active == item.name
                  ? 'active'
                  : hoverIndex == index
                  ? 'hover'
                  : ''
              "
            >
              {{ item.meta.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "./components/headers";
import Main from "./components/main";
import Footer from "./components/footer";
import routes from "@/router/mainPage";
export default {
  name: "LayOut",
  components: {
    Header,
    Main,
    Footer,
  },
  data() {
    return {
      circleNum: 0,
      circleInter: null,
      active: null,
      routes: [],
      hoverIndex: null,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        console.log("$route", to, from);
        this.active = to.name;
        setTimeout(() => {
          this.circleNum = 0;
        }, 50);
      },
      immediate: true,
    },
  },
  created() {
    this.routes = routes;
  },
  methods: {
    routerHover(index) {
      this.hoverIndex = index;
    },
    routerLeave() {
      this.hoverIndex = null;
    },
    openCircle() {
      if (this.circleNum === 0) {
        this.circleNum = 160;
      } else {
        this.circleNum = 0;
      }
    },
    closeCircle(){
      this.circleNum = 0
    }
  },
};
</script>
<style lang="scss" scoped>
.dashboardBg {
  background-image: url("../assets/images/dashboardBg.png");
}
.otherBg {
  background-image: url("../assets/images/otherBg.png");
}
.layout {
  width: 100%;
  min-height: 100%;
  background-color: #070b1e;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .layout-inner {
    display: flex;
    flex-direction: column;
    .main {
      min-height: calc(100vh - 105px - 65px);
      // display: flex;
      // align-items: center;
    }
  }
  .shadowMore {
    width: 105%;
    height: 105%;
    z-index: 88;
    position: fixed;
    top: -47px;
    right: -67px;
    background: #070b1d;
    display: flex;
    align-items: center;
    transition: all 0.6s linear;
    .shadowMore-router {
      margin-left: 139px;
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      line-height: 45px;

      .router-item {
        cursor: pointer;
        .router {
          display: inline-block;
          padding-bottom: 4px;
          margin-bottom: 77px;
          &.active {
            color: #196ff7;
            border-bottom: 6px solid #196ff7;
          }
        }
        .hover {
          color: #ffffff;
          text-indent: 30px;
        }
      }
    }
  }
}
</style>
