<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-05-29 13:44:14
 * @LastEditTime: 2021-12-13 14:58:43
-->
<template>
  <div class="footer_box">
    <div class="text">Copyright © 杭州荣鼎科技有限公司</div>
    <div class="line">|</div>
    <div class="text">
      <a href="https://beian.miit.gov.cn" target="_blank"
        >浙ICP备17013610号-3
      </a>
    </div>
    <div class="line">|</div>
    <div class="text">
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202001867"
        target="_blank"
      >
        <img
          src="@/assets/gongan.png"
          alt=""
          style="width: 18px; height: 18px"
        />
        浙公网安备33010202001867号
      </a>
    </div>
    <div class="line">|</div>
    <div class="text" @click="goAgree(1)">隐私协议</div>
    <div class="line">|</div>
    <div class="text" @click="goAgree(2)">用户协议</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    goAgree(num){
      if(num===1){
        this.$router.push({path:'/PrivacyAgreement'})
      }else{
        this.$router.push({path:'/useAgreement'})
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/index.scss";
.footer_box {
  @include rect(100%, 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  .text,
  .line {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #999999;
  }
  .text{
    a {
      color: #999999;
      text-decoration: none;
      vertical-align: middle;
    }
    img{
      vertical-align: middle;
    }
  }
  .text:hover {
    color: #fff;
    cursor: pointer;
    a {
      color: #fff;
    }
  }
  .line {
    margin: 0 16px;
  }
}
</style>
