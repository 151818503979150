<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-05-29 15:21:41
 * @LastEditTime: 2021-09-24 18:22:11
-->
<template>
  <div class="main_box">
    <transition name="fade-transform" mode="out-in">
      <router-view></router-view>
      <!-- <keep-alive>
        <router-view></router-view>
      </keep-alive> -->
    </transition>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/styles/index.scss";
.main_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
