<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-24 11:30:45
 * @LastEditTime: 2021-12-10 15:42:24
-->
<template>
  <div class="header_box">
    <div class="header_logo" @click="toDashboard">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="header_more">
      <div class="">更多</div>
      <div class="more-icon" @click="openCircle"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {
    openCircle() {
      this.$emit("openCircle");
    },
    toDashboard() {
      this.$router.push({
        path: "/dashboard",
      });
    },
  },
};
</script>
<style lang="scss" >
@import "@/styles/index.scss";
.header_box {
  @include rect(100%, auto);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header_logo {
    width: 156px;
    height: 57px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .header_more {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 65px;
    color: #fff;
    display: flex;
    align-items: center;
    .more-icon {
      width: 40px;
      height: 40px;
      margin-left: 20px;
      cursor: pointer;
      background-image: url('../../../assets/images/more.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .more-icon:hover {
      background-image: url('../../../assets/images/moreS.png');
    }
  }
}
</style>
